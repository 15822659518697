import React, { useState, useMemo, useCallback, useEffect } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { TextCell, TranslationCell, AvatarCell, LinkCell } from "../../../components/tables/MaterialTableCells";
import { Link } from "react-router-dom";
import AppointmentResourceModal from "./AppointmentResourceModal";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import AppointmentResourceListToolbar from "../../../components/tables/toolbar/AppointmentResourceListToolbar";
import { RESOURCE_STAFF_TYPE, RESOURCE_SPACE_TYPE, RESOURCE_ARTICLE_TYPE } from "../../resources/utils";
import ListActionsButton from "../../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";

function AppointmentPageResources({
    getAppointmentResources,
    addAppointmentResource,
    deleteAppointmentResource,
    getServiceOptions,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { appointment, appointmentResourcesPagination, serviceOptions, isLoading, isUpdating } = useSelector(
        (state) => state.services
    );
    const serviceId = appointment?.id;

    const [status, setStatus] = useState("active");

    const [showModalResource, setShowModalResource] = useState(false);
    const [itemToShow, setItemToShow] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    useEffect(() => {
        if (!serviceOptions) {
            getServiceOptions(profile.id);
        }
    }, [profile.id, serviceOptions, getServiceOptions]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (serviceId) {
                getAppointmentResources(serviceId, pageIndex + 1, pageSize, status);
            }
        },
        [serviceId, getAppointmentResources, status]
    );

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.SUBTITLE" />
                        </span>
                    </div>
                    {appointment && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton
                                isLoading={isUpdating}
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setShowModalResource(true)}
                            ></BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={serviceId}
                        data={useMemo(() => appointmentResourcesPagination.data, [appointmentResourcesPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={appointmentResourcesPagination.totalRows}
                        isLoading={isLoading}
                        renderToolbar={() => {
                            return <AppointmentResourceListToolbar onStatusChanged={onStatusChanged} />;
                        }}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.NAME" />,
                                    accessor: "resource",
                                    Cell: ({ row }) => {
                                        if (row.original.resource) {
                                            const resource = row.original.resource;
                                            return (
                                                <Link to={`/resources/${resource.id}/${resource.type}`}>
                                                    {AvatarCell(resource.avatarUrl, `${resource.name}`)}
                                                </Link>
                                            );
                                        } else if (row.original.resourceGroup) {
                                            const resourceGroup = row.original.resourceGroup;
                                            return LinkCell(`/resource-groups/${resourceGroup.id}`, resourceGroup.name);
                                        }

                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.TYPE" />,
                                    accessor: "type",
                                    maxWidth: 100,
                                    Cell: ({ row }) => {
                                        const type = row.original.type;
                                        if (type === "resource") {
                                            const resourceType = row.original.resource.type;
                                            if (resourceType === RESOURCE_STAFF_TYPE) {
                                                return TranslationCell("RESOURCE.TYPE.STAFF.STAFF");
                                            } else if (resourceType === RESOURCE_SPACE_TYPE) {
                                                return TranslationCell("RESOURCE.TYPE.SPACE");
                                            } else if (resourceType === RESOURCE_ARTICLE_TYPE) {
                                                return TranslationCell("RESOURCE.TYPE.ARTICLE");
                                            }
                                        } else if (type === "resource_group") {
                                            return TranslationCell(
                                                "SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.TYPE.GROUP"
                                            );
                                        }
                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.PRICE" />,
                                    accessor: "price",
                                    Cell: ({ row }) => {
                                        const price = row.original.price;
                                        if (price) {
                                            let text = price;

                                            if (row.original.currency) {
                                                text += ` ${row.original.currency.shortName}`;
                                            }

                                            return TextCell(text);
                                        }

                                        return TranslationCell(
                                            "SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.PRICE.SAME_AS_SERVICE"
                                        );
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.PAYMENT_TEMPLATE" />
                                    ),
                                    accessor: "paymentTemplate",
                                    Cell: ({ row }) => {
                                        const paymentTemplate = row.original.paymentTemplate;
                                        if (paymentTemplate) {
                                            const url = `/payment-templates/${paymentTemplate.id}`;
                                            return LinkCell(url, paymentTemplate.name);
                                        }

                                        return TranslationCell(
                                            "SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.PAYMENT_TEMPLATE.SAME_AS_SERVICE"
                                        );
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.BOOKING_COUNT" />
                                    ),
                                    accessor: "bookingCount",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "actions",
                                    textAlign: "center",
                                    Cell: ({ row }) => {
                                        return (
                                            <ListActionsButton
                                                menuItems={[
                                                    {
                                                        icon: <LinkIcon fontSize="small" />,
                                                        label: <FormattedMessage id="COMMON.OPEN" />,
                                                        onClick: () => {
                                                            setShowModalResource(true);
                                                            setItemToShow(row.original);
                                                        },
                                                    },
                                                    {
                                                        icon: <DeleteOutlinedIcon fontSize="small" />,
                                                        label: <FormattedMessage id="COMMON.DELETE" />,
                                                        onClick: () => setItemToDelete(row.original),
                                                    },
                                                ]}
                                            />
                                        );
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
            {showModalResource && (
                <AppointmentResourceModal
                    show={showModalResource}
                    onCloseClicked={() => {
                        setShowModalResource(false);
                        setItemToShow(null);
                    }}
                    appointment={appointment}
                    serviceResourceOrGroup={itemToShow}
                    serviceOptions={serviceOptions}
                    onResourceAdded={(data) => {
                        fetchData({ pageIndex: 0, pageSize: 10 });
                        setShowModalResource(false);
                        setItemToShow(null);
                    }}
                    onResourceUpdated={(data) => {
                        fetchData({ pageIndex: 0, pageSize: 10 });
                        setShowModalResource(false);
                        setItemToShow(null);
                    }}
                />
            )}
            <YesNoDialog
                title={
                    itemToDelete?.resource ? (
                        <FormattedMessage
                            id="SERVICE.APPOINTMENT.RESOURCES.DELETE.TITLE"
                            values={{ name: itemToDelete.resource.name }}
                        />
                    ) : (
                        <FormattedMessage
                            id="SERVICE.APPOINTMENT.RESOURCES.DELETE.TITLE"
                            values={{ name: itemToDelete?.resourceGroup?.name }}
                        />
                    )
                }
                description={<FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.DELETE.TEXT" />}
                open={itemToDelete ? true : false}
                onOpenChanged={() => setItemToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteAppointmentResource(serviceId, itemToDelete.id);
                    }
                    setItemToDelete(null);
                }}
            />
        </Card>
    );
}
export default connect(null, { ...serviceActions, ...errorActions })(AppointmentPageResources);
